.v-error-block {
  text-align: center;
  position: relative;
  p {
    font-weight: 100;
    display: block;
    padding: 10px 0;
    margin: 0;
    color: black;
    font-size: 1.5em;
  }
  .v-error-title {
    position: absolute;
    background: #fff;
    border: 1px solid #ddd;
    border-radius:4px;
    padding: 6px 23px;
    font-size: 16px;
    left: 35%;
    top: 64px;
    &:after {
      content: '';
      position: absolute;
      right: 20px;
      bottom: -10px;
      width: 18px;
      height: 18px;
      transform: rotate(-46deg);
      background: #fff;
      border: 1px solid #ddd;
      border-top: none;
      border-right: none;
    }
    @media (max-width: 990px) {
      left: 0;
    }
    @media (max-width: 768px) {
      position: relative;
      top: 0;
      margin-bottom: 20px;
    }
  }
}